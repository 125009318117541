import { FC, useEffect } from "react";
import styles from "./style.module.scss";
import location from "../../../app/assets/profileCard/location.svg";
import videos from "../../../app/assets/profileCard/videos.svg";
import view from "../../../app/assets/profileCard/view.svg";
import { getDataUser } from "app/api/api";
import { useDispatch, useSelector } from "app/service/hooks/hooks";
import { UpdateProfilePhoto } from "components/Profile/LoadPhoto/LoadPhoto";
import { setActiveButton } from "app/service/profileCard/profileCardSlice";

const ProfileCard: FC = () => {
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await getDataUser(dispatch);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const savedButton = localStorage.getItem("activeButton");
    if (savedButton) {
      dispatch(setActiveButton(savedButton));
    }
  }, [dispatch]);

  return (
    <div className={styles.profile_box}>
      <div className={styles.profile_img_box}>
        <UpdateProfilePhoto
          initialPhotoUrl={userData?.photo ? userData?.photo! : ""}
        />
      </div>
      <div className={styles.username_box}>
        {userData && (
          <p className={styles.username}>
            <span>
              {userData?.first_name
                ? userData?.first_name.toUpperCase()
                : "ИМЯ"}{" "}
            </span>
            <span>
              {userData?.last_name
                ? userData?.last_name.toUpperCase()
                : "ФАМИЛИЯ"}
            </span>
          </p>
        )}
      </div>

      <div className={styles.containerInfo}>
        <div className={styles.box_loc_vid_view}>
          <p className={styles.location}>
            <img
              className={styles.location_img}
              src={location}
              alt='location_icon'
            />
            <span className={styles.locationText}>
              {userData?.city && userData?.country
                ? `${userData.city}, ${userData.country}`
                : "Не задано"}
            </span>
          </p>
          <div className={styles.videos_view}>
            <p className={styles.vid_see}>
              <img
                className={styles.vid_see_img}
                src={videos}
                alt='videos_icon'
              />
              0
            </p>
            <p className={styles.vid_see}>
              <img
                className={styles.vid_see_img}
                src={view}
                alt='view_icon'
              />
              0
            </p>
          </div>
        </div>
        <div className={styles.info_me}>
          <h3 className={styles.title_me}>Обо мне:</h3>
          <p className={styles.text_me}>
            {" "}
            {userData?.description
              ? userData?.description
              : "Заполнить описание"}
          </p>{" "}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
